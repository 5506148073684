import { SideKickViewsWelcomeCheckmark } from './components'
import { SCSideKickViewsWelcomeListItem, SCSideKickViewsWelcomeListItemContent } from './styles'
import { ISideKickViewsWelcomeListItem } from './types'

const SideKickViewsWelcomeListItem: React.FC<ISideKickViewsWelcomeListItem> = ({
  children,
  url
}: ISideKickViewsWelcomeListItem) => {
  return (
    <SCSideKickViewsWelcomeListItem>
      <div>
        <SideKickViewsWelcomeCheckmark />
      </div>
      <SCSideKickViewsWelcomeListItemContent>
        <div>{children}</div>
        <div>
          <a href={url} rel='noreferrer' target='_blank'>
            {url}
          </a>
        </div>
      </SCSideKickViewsWelcomeListItemContent>
    </SCSideKickViewsWelcomeListItem>
  )
}

export { SideKickViewsWelcomeListItem }
