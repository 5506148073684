import styled from 'styled-components'

// Stats
export const SC_Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 11px;
  gap: 5px;
`

export const SC_Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: 'Poppins', sans-serif;
`

export const SC_Icon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 20px;
`

export const SC_Label = styled.div``

export const SC_Value = styled.div`
  font-weight: 700;
  padding-left: 2px;
`
