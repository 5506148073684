const SideKickViewsHomeIconsBag: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: '#E1F3E7',
        display: 'flex',
        width: '48px',
        height: '48px',
        padding: '10px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px'
      }}
    >
      <svg fill='none' height='28' viewBox='0 0 24 28' width='24' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_592_240)'>
          <path
            d='M8.57143 6.125C8.57143 4.19453 10.1089 2.625 12 2.625C13.8911 2.625 15.4286 4.19453 15.4286 6.125V8.75H8.57143V6.125ZM6 8.75H2.57143C1.15179 8.75 0 9.92578 0 11.375V22.75C0 25.6484 2.30357 28 5.14286 28H18.8571C21.6964 28 24 25.6484 24 22.75V11.375C24 9.92578 22.8482 8.75 21.4286 8.75H18V6.125C18 2.73984 15.3161 0 12 0C8.68393 0 6 2.73984 6 6.125V8.75ZM7.28571 11.375C7.62671 11.375 7.95373 11.5133 8.19485 11.7594C8.43597 12.0056 8.57143 12.3394 8.57143 12.6875C8.57143 13.0356 8.43597 13.3694 8.19485 13.6156C7.95373 13.8617 7.62671 14 7.28571 14C6.94472 14 6.6177 13.8617 6.37658 13.6156C6.13546 13.3694 6 13.0356 6 12.6875C6 12.3394 6.13546 12.0056 6.37658 11.7594C6.6177 11.5133 6.94472 11.375 7.28571 11.375ZM15.4286 12.6875C15.4286 12.3394 15.564 12.0056 15.8051 11.7594C16.0463 11.5133 16.3733 11.375 16.7143 11.375C17.0553 11.375 17.3823 11.5133 17.6234 11.7594C17.8645 12.0056 18 12.3394 18 12.6875C18 13.0356 17.8645 13.3694 17.6234 13.6156C17.3823 13.8617 17.0553 14 16.7143 14C16.3733 14 16.0463 13.8617 15.8051 13.6156C15.564 13.3694 15.4286 13.0356 15.4286 12.6875Z'
            fill='#1F753D'
          />
        </g>
        <defs>
          <clipPath id='clip0_592_240'>
            <rect fill='white' height='28' width='24' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export { SideKickViewsHomeIconsBag }
