// React
import { useContext, useState } from 'react'
// MainStem - UI
import { Button } from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi } from 'api-new'
// DEPRECATE : MainStem - API
import { apiAuthCurrentUser } from 'api/auth/current-user'
// Global - Context
import { AppContext } from 'context'
// Local - Components
import { SideKickViewsWelcomeListItem } from './components'
// Local - Styled Components
import {
  SCSideKickViewsWelcomeButton,
  SCSideKickViewsWelcomeDescription,
  SCSideKickViewsWelcomeListItemWrapper,
  SCSideKickViewsWelcomeTitle,
  SCSideKickViewsWelcomeWrapper
} from './styles'
// Local - Types
import { ISideKickViewsWelcome } from './types'

const SideKickViewsWelcome: React.FC<ISideKickViewsWelcome> = ({ onTermsAccepted }: ISideKickViewsWelcome) => {
  // Global - Context
  const { loggedInUser, setLoggedInUser } = useContext(AppContext)
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const handleAcceptContinue = () => {
    setLoading(true)
    MainStemApi.api.sidekickTermsAccept().then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        apiAuthCurrentUser().then((apiResponseCurrentUser) => {
          setLoggedInUser(apiResponseCurrentUser)
        })
        setLoading(false)
        onTermsAccepted()
      }
    })
  }

  return (
    <>
      <SCSideKickViewsWelcomeWrapper>
        <div>
          <SCSideKickViewsWelcomeTitle>Hello, {loggedInUser.friendlyName}!</SCSideKickViewsWelcomeTitle>
          <SCSideKickViewsWelcomeDescription>
            I am MainStem SideKick. I am here to help you with any questions you may have. Please feel free to ask me
            anything. I will also provide you with helpful tips and tricks to make your experience on MainStem even
            better.
          </SCSideKickViewsWelcomeDescription>
          <hr />
          <SCSideKickViewsWelcomeDescription>
            Before we proceed to the next step, please agree to the terms and conditions given below:
          </SCSideKickViewsWelcomeDescription>
          <SCSideKickViewsWelcomeListItemWrapper>
            <SideKickViewsWelcomeListItem url='https://www.mainstem.io/terms-conditions'>
              You can view our <strong>terms and conditions</strong> at this link
            </SideKickViewsWelcomeListItem>
            <SideKickViewsWelcomeListItem url='https://www.mainstem.io/privacy-policy'>
              You can view our <strong>privacy policy</strong> at this link
            </SideKickViewsWelcomeListItem>
            <SideKickViewsWelcomeListItem url='https://www.mainstem.io/security/policy'>
              You can view our <strong>security policy</strong> at this link
            </SideKickViewsWelcomeListItem>
          </SCSideKickViewsWelcomeListItemWrapper>
          <SCSideKickViewsWelcomeButton>
            <Button color='fancy' loading={loading} onClick={handleAcceptContinue}>
              Accept & Continue
            </Button>
          </SCSideKickViewsWelcomeButton>
        </div>
      </SCSideKickViewsWelcomeWrapper>
    </>
  )
}

export { SideKickViewsWelcome }
