const SideKickViewsHomeIconsLink: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: '#FDE8E8',
        display: 'flex',
        width: '48px',
        height: '48px',
        padding: '10px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px'
      }}
    >
      <svg fill='none' height='28' viewBox='0 0 24 28' width='24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M22.3172 15.0722C24.5609 12.3091 24.5609 7.8344 22.3172 5.07133C20.3316 2.62614 17.2023 2.30826 14.9188 4.31821L14.8553 4.37201C14.2834 4.87572 14.1524 5.85379 14.5614 6.55312C14.9705 7.25245 15.7647 7.41872 16.3326 6.91501L16.3961 6.86121C17.6709 5.74132 19.4142 5.91737 20.5182 7.28179C21.7692 8.82226 21.7692 11.3164 20.5182 12.8568L16.0625 18.3536C14.8116 19.8941 12.7863 19.8941 11.5354 18.3536C10.4274 16.9892 10.2844 14.8423 11.1938 13.2774L11.2375 13.1992C11.6466 12.4949 11.5115 11.5169 10.9437 11.018C10.3758 10.5192 9.57756 10.6806 9.1725 11.3799L9.12882 11.4582C7.49268 14.2653 7.75081 18.1189 9.73641 20.5641C11.9801 23.3271 15.6138 23.3271 17.8575 20.5641L22.3172 15.0722ZM1.6828 13.9278C-0.560933 16.6909 -0.560933 21.1656 1.6828 23.9287C3.6684 26.3739 6.79772 26.6917 9.08116 24.6818L9.1447 24.628C9.71656 24.1243 9.84761 23.1462 9.43857 22.4469C9.02954 21.7476 8.23529 21.5813 7.66741 22.085L7.60387 22.1388C6.32911 23.2587 4.58575 23.0826 3.48176 21.7182C2.23083 20.1728 2.23083 17.6788 3.48176 16.1383L7.93745 10.6464C9.18839 9.1059 11.2137 9.1059 12.4646 10.6464C13.5726 12.0108 13.7156 14.1577 12.8062 15.7275L12.7625 15.8057C12.3534 16.5099 12.4885 17.488 13.0563 17.9868C13.6242 18.4857 14.4224 18.3243 14.8275 17.625L14.8712 17.5467C16.5073 14.7347 16.2492 10.8811 14.2636 8.43592C12.0199 5.67285 8.3862 5.67285 6.14247 8.43592L1.6828 13.9278Z'
          fill='#E91C1C'
        />
      </svg>
    </div>
  )
}

export { SideKickViewsHomeIconsLink }
