// React
import { useContext } from 'react'
// Global - Context
import { AppContext } from 'context'
// Local - Components
import {
  SideKickViewsHomeActionTile,
  SideKickViewsHomeIconsBag,
  SideKickViewsHomeIconsHand,
  SideKickViewsHomeIconsLightbulb,
  SideKickViewsHomeIconsLink,
  SideKickViewsHomeIconsTruck
} from './components'
// Local - Styled Components
import {
  SCSideKickViewsHome,
  SCSideKickViewsHomeActionTiles,
  SCSideKickViewsHomeBottom,
  SCSideKickViewsHomeBottomTag,
  SCSideKickViewsHomeBottomTags,
  SCSideKickViewsHomeBottomTitle,
  SCSideKickViewsHomeDescription,
  SCSideKickViewsHomeTitle,
  SCSideKickViewsHomeTop
} from './styles'
// Local - Types
import { ISideKickViewsHome } from './types'

const SideKickViewsHome: React.FC<ISideKickViewsHome> = ({ onGetConversation }: ISideKickViewsHome) => {
  // Global - Context
  const { loggedInUser } = useContext(AppContext)

  return (
    <>
      <SCSideKickViewsHome>
        <SCSideKickViewsHomeTop>
          <SideKickViewsHomeIconsHand />
          <SCSideKickViewsHomeTitle>Hello, {loggedInUser.friendlyName}</SCSideKickViewsHomeTitle>
          <SCSideKickViewsHomeDescription>How can I help you today?</SCSideKickViewsHomeDescription>
        </SCSideKickViewsHomeTop>
        <SCSideKickViewsHomeActionTiles>
          <SideKickViewsHomeActionTile
            description='Ask me questions about products, how to order, or anything else you need help with. I am here to help!'
            icon={<SideKickViewsHomeIconsLightbulb />}
            onClick={() => {
              onGetConversation('What kind of questions can I ask about products?')
            }}
            title='Ask About Products'
          />
          <SideKickViewsHomeActionTile
            description='Just ask me about suppliers or anything else you want to know about our in-network suppliers or your own private suppliers.'
            icon={<SideKickViewsHomeIconsTruck />}
            onClick={() => {
              onGetConversation('What kind of questions can I ask about suppliers?')
            }}
            title='Learn About Suppliers'
          />
          <SideKickViewsHomeActionTile
            description='Ask me about your orders, shipment details, or anything else related to your recent purchases.'
            icon={<SideKickViewsHomeIconsBag />}
            onClick={() => {
              onGetConversation('What kind of questions can I ask about orders?')
            }}
            title='Check Up On Your Orders'
          />
          <SideKickViewsHomeActionTile
            description='Just paste the URL of the product you are interested in, and I will help you with all the details you need to know.'
            icon={<SideKickViewsHomeIconsLink />}
            onClick={() => {
              onGetConversation('What happens if I paste a URL?')
            }}
            title='Provide Me With A Product URL'
          />
        </SCSideKickViewsHomeActionTiles>
        <SCSideKickViewsHomeBottom>
          <SCSideKickViewsHomeBottomTitle>Select topics to personalize your experience:</SCSideKickViewsHomeBottomTitle>
          <SCSideKickViewsHomeBottomTags>
            <SCSideKickViewsHomeBottomTag
              onClick={() => {
                onGetConversation('How can I view options for a product?')
              }}
            >
              Product Optionality
            </SCSideKickViewsHomeBottomTag>
            <SCSideKickViewsHomeBottomTag
              onClick={() => {
                onGetConversation('What kind of questions can I ask?')
              }}
            >
              More About SideKick
            </SCSideKickViewsHomeBottomTag>
            <SCSideKickViewsHomeBottomTag
              onClick={() => {
                onGetConversation('Tell me about MainStem the platform.')
              }}
            >
              About MainStem Platform
            </SCSideKickViewsHomeBottomTag>
            <SCSideKickViewsHomeBottomTag
              onClick={() => {
                onGetConversation('How can I submit a request for proposal?')
              }}
            >
              Product Requisitions
            </SCSideKickViewsHomeBottomTag>
            <SCSideKickViewsHomeBottomTag
              onClick={() => {
                onGetConversation('How do shipments work in MainStem?')
              }}
            >
              MainStem Delivery
            </SCSideKickViewsHomeBottomTag>
          </SCSideKickViewsHomeBottomTags>
        </SCSideKickViewsHomeBottom>
      </SCSideKickViewsHome>
    </>
  )
}

export { SideKickViewsHome }
