import styled from 'styled-components'

export const SCSideKickViewsHome = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 100%;
  width: 80%;
`

export const SCSideKickViewsHomeTop = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const SCSideKickViewsHomeTitle = styled.h1`
  color: rgb(99, 39, 173);
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`

export const SCSideKickViewsHomeDescription = styled.p`
  color: rgb(125, 132, 149);
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const SCSideKickViewsHomeActionTiles = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`
export const SCSideKickViewsHomeBottom = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
export const SCSideKickViewsHomeBottomTitle = styled.h2`
  color: rgb(125, 132, 149);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-top: 24px;
`

export const SCSideKickViewsHomeBottomTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 16px;
`

export const SCSideKickViewsHomeBottomTag = styled.div`
  color: rgb(99, 39, 173);
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid rgba(99, 39, 173, 0.08);
  background: rgba(99, 39, 173, 0.07);

  &:hover {
    background: rgba(99, 39, 173, 0.1);
    cursor: pointer;
  }
`
