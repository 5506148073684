// Styled Components
import styled from 'styled-components'
// Local - Types
import { ISC_Ribbon } from './types'

export const SC_Ribbon = styled.div<ISC_Ribbon>`
  background-color: ${({ color }) => color};
  border-image: conic-gradient(#0008 0 0) 51% / var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),
    100% 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    var(--f) calc(100% - var(--f)),
    0 100%,
    0 calc(100% - var(--f)),
    999px calc(100% - var(--f) - 999px),
    calc(100% - 999px) calc(100% - var(--f) - 999px)
  );
  color: #fff;
  font-size: 12px;
  left: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transform: translate(calc((cos(45deg) - 1) * 100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  user-select: none;
  --f: 0.5em;
`
