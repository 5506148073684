// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { SelectSingle } from '@mainstem/react-mainstem'
// Local - Types
import { ISelectYear } from './types'

const today = new Date()

const years = Array.from({ length: 10 }, (_, i) => {
  return {
    label: (today.getFullYear() - i).toString(),
    value: today.getFullYear() - i
  }
})

const SelectYear: React.FC<ISelectYear> = ({ defaultValue, onChange, required }: ISelectYear) => {
  const [year, setYear] = useState<any | undefined>(undefined)

  useEffect(() => {
    if (defaultValue) {
      const yearFound = years.find((option) => option.value === defaultValue)
      setYear(yearFound)
    }
  }, [defaultValue])

  return (
    <>
      <SelectSingle
        label='Year'
        labelRequired={required}
        onChange={(newValue) => {
          setYear(newValue)
          onChange(newValue?.value)
        }}
        options={years}
        value={year}
      />
    </>
  )
}

export { SelectYear }
