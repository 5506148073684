import { theme } from '@mainstem/react-mainstem'
import styled from 'styled-components'

export const SCSupplierHeaderContainer = styled.div`
  align-items: center;
  box-shadow: 0 0 2px 0 #00000061;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 1rem;
`

export const SCSupplierHeaderLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  padding-left: 20px;
`

export const SCSupplierHeaderLogoWrapper = styled.div`
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e8e8e8;
  width: 200px;
`

export const SCSupplierHeaderLogo = styled.img`
  width: 100%;
`

export const SCSupplierHeaderBackgroundImageWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const SCSupplierHeaderBackgroundImage = styled.div`
  background-position: 50% !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  height: 150px;
  left: 0;
  top: 0;
  width: 100%;
  border: 1px solid #dee2e6 !important;
  font-weight: 400;
  font-size: 14px;
  font-family: '${theme.fonts.primary}', sans-serif;
  color: #fff !important;

  :before {
    background: rgba(0, 30, 98, 0.65);
    content: '';
    height: 150px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const SCSupplierHeaderBackgroundImageContentWrapper = styled.div`
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

export const SCSupplierHeaderBackgroundImageContent = styled.div`
  overflow: hidden;
  padding: 20px;
`
