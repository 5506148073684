import styled from 'styled-components'

export const SCSideKickViewsChatAvatarBot = styled.div`
  position: relative;
`

export const SCSideKickViewsChatAvatarBotOnlineStatus = styled.div`
  svg {
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
`
