// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { Loader, SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// MainStem - API
import { Api } from 'api-new'
const mainStemApi = new Api()
// Local - Types
import { ISelectSingleLocation } from './types'
import { LoaderSelect } from 'components/Loader'

const SelectSingleLocation: React.FC<ISelectSingleLocation> = ({
  defaultValue,
  label,
  onSelected,
  required
}: ISelectSingleLocation) => {
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  // View State
  const [options, setOptions] = useState<ISelectOption[] | undefined>(undefined)
  const [selectedValue, setSelectedValue] = useState<any | undefined>(undefined)

  useEffect(() => {
    if (defaultValue && options) {
      const matchingValue = options.find((option) => option.value === defaultValue)
      setSelectedValue(matchingValue)
      onSelected(matchingValue)
    }
  }, [defaultValue, options])

  useOnFirstLoad(() => {
    mainStemApi.api
      .locationsList()
      .then((response) => {
        if (response.data.locations) {
          const formattedOptions = response.data.locations.map((location) => {
            return {
              label: location.name,
              value: location.id
            } as ISelectOption
          })
          setOptions(formattedOptions)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  })

  return (
    <>
      {loading || !options ? (
        <LoaderSelect label={label ? label : 'Select a location:'} />
      ) : (
        <>
          <SelectSingle
            label={label ? label : 'Select a location:'}
            labelRequired={required}
            onChange={(newValue) => {
              setSelectedValue(newValue)
              onSelected(newValue)
            }}
            options={options}
            placeholder='Select a location'
            value={selectedValue}
          />
        </>
      )}
    </>
  )
}

export { SelectSingleLocation }
