import { ISideKickViewsChatIconThumbsDown } from './types'

const SideKickViewsChatIconThumbsDown: React.FC<ISideKickViewsChatIconThumbsDown> = ({
  onClick
}: ISideKickViewsChatIconThumbsDown) => {
  return (
    <svg
      fill='none'
      height='16'
      onClick={() => onClick()}
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.88125 14.9124C7.075 15.253 8.32188 14.5624 8.6625 13.3687L8.84062 12.7437C8.95625 12.3374 9.16562 11.9624 9.45 11.6499L11.0531 9.8874C11.3312 9.58115 11.3094 9.10615 11.0031 8.82803C10.6969 8.5499 10.2219 8.57178 9.94375 8.87803L8.34062 10.6405C7.9 11.1249 7.57812 11.703 7.4 12.3312L7.22188 12.9562C7.10938 13.353 6.69375 13.5843 6.29375 13.4718C5.89375 13.3593 5.66562 12.9437 5.77812 12.5437L5.95625 11.9187C6.13438 11.2968 6.41563 10.7093 6.7875 10.1843C6.95 9.95615 6.96875 9.65615 6.84062 9.40615C6.7125 9.15615 6.45625 8.9999 6.175 8.9999H2C1.725 8.9999 1.5 8.7749 1.5 8.4999C1.5 8.2874 1.63437 8.10303 1.825 8.03115C2.05625 7.94365 2.23125 7.7499 2.29062 7.50928C2.35 7.26865 2.2875 7.01553 2.125 6.83115C2.04688 6.74365 2 6.62803 2 6.4999C2 6.25615 2.175 6.05303 2.40625 6.00928C2.6625 5.95928 2.87813 5.78115 2.96875 5.53428C3.05937 5.2874 3.01875 5.0124 2.85625 4.80615C2.79062 4.72178 2.75 4.61553 2.75 4.49678C2.75 4.2874 2.88125 4.10303 3.06875 4.03115C3.42813 3.89053 3.62187 3.50303 3.51875 3.13115C3.50625 3.09053 3.5 3.04365 3.5 2.99678C3.5 2.72178 3.725 2.49678 4 2.49678H7.04688C7.44063 2.49678 7.82812 2.6124 8.15625 2.83115L10.0844 4.11553C10.4281 4.34678 10.8938 4.25303 11.125 3.90615C11.3562 3.55928 11.2625 3.09678 10.9156 2.86553L8.9875 1.58115C8.4125 1.19678 7.7375 0.993652 7.04688 0.993652L4 0.999902C2.91562 0.999902 2.03438 1.8624 2 2.9374C1.54375 3.30303 1.25 3.86553 1.25 4.4999C1.25 4.64053 1.26562 4.7749 1.29062 4.90615C0.809375 5.27178 0.5 5.8499 0.5 6.4999C0.5 6.70303 0.53125 6.8999 0.5875 7.08428C0.224999 7.44678 0 7.94678 0 8.4999C0 9.60303 0.89375 10.4999 2 10.4999H4.88437C4.7375 10.8249 4.6125 11.1624 4.51562 11.5062L4.3375 12.1312C3.99687 13.3249 4.6875 14.5718 5.88125 14.9124ZM15 11.9999C15.5531 11.9999 16 11.553 16 10.9999V3.9999C16 3.44678 15.5531 2.9999 15 2.9999H13C12.4469 2.9999 12 3.44678 12 3.9999V10.9999C12 11.553 12.4469 11.9999 13 11.9999H15Z'
        fill={'#BEC1CA'}
      />
    </svg>
  )
}

export { SideKickViewsChatIconThumbsDown }
