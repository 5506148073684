import { ISideKickViewsChatIconThumbsUp } from './types'

const SideKickViewsChatIconThumbsUp: React.FC<ISideKickViewsChatIconThumbsUp> = ({
  onClick
}: ISideKickViewsChatIconThumbsUp) => {
  return (
    <svg
      fill='none'
      height='16'
      onClick={() => onClick()}
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1187 1.08741C8.925 0.746789 7.67812 1.43741 7.3375 2.63116L7.15938 3.25616C7.04375 3.66241 6.83438 4.03741 6.55 4.34991L4.94688 6.11241C4.66875 6.41866 4.69062 6.89366 4.99687 7.17179C5.30312 7.44991 5.77813 7.42804 6.05625 7.12179L7.65938 5.35929C8.1 4.87491 8.42188 4.29679 8.6 3.66866L8.77812 3.04366C8.89062 2.64679 9.30625 2.41554 9.70625 2.52804C10.1062 2.64054 10.3344 3.05616 10.2219 3.45616L10.0437 4.08116C9.86562 4.70304 9.58437 5.29054 9.2125 5.81554C9.05 6.04366 9.03125 6.34366 9.15938 6.59366C9.2875 6.84366 9.54375 6.99991 9.825 6.99991H14C14.275 6.99991 14.5 7.22491 14.5 7.49991C14.5 7.71241 14.3656 7.89679 14.175 7.96866C13.9438 8.05616 13.7688 8.24992 13.7094 8.49054C13.65 8.73116 13.7125 8.98429 13.875 9.16866C13.9531 9.25616 14 9.37179 14 9.49991C14 9.74366 13.825 9.94679 13.5938 9.99054C13.3375 10.0405 13.1219 10.2187 13.0312 10.4655C12.9406 10.7124 12.9812 10.9874 13.1438 11.1937C13.2094 11.278 13.25 11.3843 13.25 11.503C13.25 11.7124 13.1187 11.8968 12.9312 11.9687C12.5719 12.1093 12.3781 12.4968 12.4812 12.8687C12.4937 12.9093 12.5 12.9562 12.5 13.003C12.5 13.278 12.275 13.503 12 13.503H8.95312C8.55937 13.503 8.17188 13.3874 7.84375 13.1687L5.91563 11.8843C5.57188 11.653 5.10625 11.7468 4.875 12.0937C4.64375 12.4405 4.7375 12.903 5.08437 13.1343L7.0125 14.4187C7.5875 14.803 8.2625 15.0062 8.95312 15.0062H12C13.0844 15.0062 13.9656 14.1437 14 13.0687C14.4563 12.703 14.75 12.1405 14.75 11.5062C14.75 11.3655 14.7344 11.2312 14.7094 11.0999C15.1906 10.7343 15.5 10.1562 15.5 9.50616C15.5 9.30304 15.4688 9.10616 15.4125 8.92179C15.775 8.55304 16 8.05304 16 7.49991C16 6.39679 15.1062 5.49991 14 5.49991H11.1156C11.2625 5.17491 11.3875 4.83741 11.4844 4.49366L11.6625 3.86866C12.0031 2.67491 11.3125 1.42804 10.1187 1.08741ZM1 5.99991C0.446875 5.99991 0 6.44679 0 6.99991V13.9999C0 14.553 0.446875 14.9999 1 14.9999H3C3.55312 14.9999 4 14.553 4 13.9999V6.99991C4 6.44679 3.55312 5.99991 3 5.99991H1Z'
        fill={'#BEC1CA'}
      />
    </svg>
  )
}

export { SideKickViewsChatIconThumbsUp }
