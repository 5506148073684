// Routing
import { Link } from 'react-router-dom'
// Styled Components
import styled from 'styled-components'

export const SC_Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const SC_ImageWrapper = styled.div`
  padding: 0px 15px 15px 0px;
  width: 250px;

  img {
    border: 1px solid #e0e0e0;
    width: 100%;
  }
`

export const SC_Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
`

// Supplier Logo
export const SC_Content_SupplierLogo = styled.div`
  img {
    max-height: 40px;
    max-width: 100%;
  }
`

// Titles
export const SC_Content_Title = styled(Link)`
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
`

// Description
export const SC_Content_Description = styled.div`
  font-size: 12px;
  max-height: 50px;
  overflow: hidden;
`

// Pricing
export const SC_Content_DetailsRow = styled.div`
  border-top: 1px dotted #e0e0e0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  padding-top: 10px;
`

export const SC_Content_DetailsRow_Pricing = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
`

export const SC_Content_DetailsRow_Pricing_Total = styled.div`
  border-right: 1px solid #e0e0e0;
  font-size: 25px;
  font-weight: 500;
  padding-right: 10px;
`

export const SC_Content_DetailsRow_Pricing_PerUnit = styled.div`
  font-size: 12px;
  padding-left: 5px;
`

// Actions
export const SC_Content_Actions = styled.div`
  border-top: 1px dotted #e0e0e0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  margin-top: 5px;
  padding-top: 5px;
`

export const SC_Content_Actions_Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 15px;
  justify-content: center;
`
