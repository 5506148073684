import styled from 'styled-components'

export const SCSideKickViewsChatAvatarUserInitials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64c5a4;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 15px;
`
