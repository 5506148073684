const SideKickViewsHomeIconsHand: React.FC = () => {
  return (
    <svg fill='none' height='40' viewBox='0 0 40 40' width='40' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_398_5078)'>
        <path
          d='M27.5156 6.42948L27.4219 5.76542C27.1875 4.11698 25.8906 2.82011 24.2422 2.58573L23.5781 2.49198C22.8985 2.39823 22.4219 1.75761 22.5156 1.07792C22.6094 0.398235 23.2422 -0.0861403 23.9297 0.0154222L24.5938 0.109172C27.3438 0.499797 29.5078 2.66386 29.8985 5.41386L29.9922 6.07792C30.086 6.75761 29.6172 7.39823 28.9297 7.49198C28.2422 7.58573 27.6094 7.11698 27.5156 6.42948ZM14.4844 5.51542C13.5078 4.53886 13.5078 2.95292 14.4844 1.97636C15.461 0.999797 17.0469 0.999797 18.0235 1.97636L33.0235 16.9764C33.2813 17.2342 33.7578 17.0623 33.7578 16.6873V10.617C33.7578 8.89042 35.1563 7.49198 36.8828 7.49198C38.6094 7.49198 40.0078 8.89042 40.0078 10.617V24.3904C40.0078 29.0935 37.8281 33.5232 34.1094 36.3982C30.1328 39.4764 25.0781 40.2889 20.5469 38.9607C18.3281 38.406 16.2266 37.2576 14.4844 35.5154L14.1016 35.1326L5.7344 26.7654L3.2344 24.2654C2.25784 23.2889 2.25784 21.7029 3.2344 20.7264C4.21096 19.7498 5.7969 19.7498 6.77346 20.7264L9.26565 23.2185L9.27346 23.2264L12.9141 26.8748C13.3203 27.281 13.9766 27.281 14.375 26.8748C14.7735 26.4685 14.7813 25.8123 14.375 25.4139L4.4844 15.5154C3.50784 14.5389 3.50784 12.9529 4.4844 11.9764C5.46096 10.9998 7.0469 10.9998 8.02346 11.9764L17.9141 21.8748C18.3203 22.281 18.9766 22.281 19.375 21.8748C19.7735 21.4685 19.7813 20.8123 19.375 20.4139L8.2344 9.26542C7.25784 8.28886 7.25784 6.70292 8.2344 5.72636C9.21096 4.7498 10.7969 4.7498 11.7735 5.72636L22.9141 16.8748C23.3203 17.281 23.9766 17.281 24.375 16.8748C24.7735 16.4685 24.7813 15.8123 24.375 15.4139L14.4844 5.51542ZM1.07034 27.5154C1.75002 27.4139 2.39065 27.8904 2.4844 28.5779L2.57815 29.242C2.81252 30.8904 4.1094 32.1873 5.75784 32.4217L6.4219 32.5154C7.10159 32.6092 7.57815 33.2498 7.4844 33.9295C7.39065 34.6092 6.75002 35.0857 6.07034 34.992L5.40627 34.8982C2.65627 34.5076 0.492211 32.3435 0.101586 29.5935L0.0156488 28.9295C-0.0781012 28.2498 0.390649 27.6092 1.07815 27.5154H1.07034Z'
          fill='#EDAE39'
        />
      </g>
      <defs>
        <clipPath id='clip0_398_5078'>
          <rect fill='white' height='40' width='40' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { SideKickViewsHomeIconsHand }
