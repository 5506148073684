// React
import { createContext } from 'react'
// MainStem - API
import { MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse } from 'api-new'

export const AppContext = createContext({} as AppContextDetails)

export interface AppContextDetails {
  addMessages: (messages: Array<IMessage>) => void
  loggedInUser: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse
  messages: Array<IMessage>
  particlesInitiated: boolean
  permissions: any
  setLoggedInUser: React.Dispatch<
    React.SetStateAction<MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse | undefined>
  >
  setPermissions: React.Dispatch<React.SetStateAction<any | undefined>>
  setUserToken: React.Dispatch<React.SetStateAction<string | undefined>>
  userToken: string
}

export type IMessage = {
  message: React.ReactNode
  type: 'user' | 'bot'
}
