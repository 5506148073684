import styled from 'styled-components'

export const SCSideKickViewsWelcomeWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 80%;
  margin: 0px auto;
  width: 80%;
`

export const SCSideKickViewsWelcomeTitle = styled.h1`
  color: #6327ad;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding-bottom: 12px;
`

export const SCSideKickViewsWelcomeDescription = styled.p`
  color: #7d8495;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const SCSideKickViewsWelcomeListItemWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
  padding: 25px;
`

export const SCSideKickViewsWelcomeButton = styled.div`
  button {
    background: rgb(129, 78, 194);
  }
`
