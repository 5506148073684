// React
import { useState } from 'react'
// MainStem - UI
import { InputText } from '@mainstem/react-mainstem'
// Local - Styles
import { SCSideKickInputContainer } from './styles'
// Local - Types
import { ISideKickInput } from './types'

const SideKickInput: React.FC<ISideKickInput> = ({ onGetConversation }: ISideKickInput) => {
  // View State
  const [messageText, setMessageText] = useState<string | undefined>(undefined)

  return (
    <>
      <SCSideKickInputContainer>
        <InputText
          onChange={(newValue) => {
            setMessageText(newValue)
          }}
          onEnter={() => {
            if (messageText) {
              onGetConversation(messageText)
              setMessageText(undefined)
            }
          }}
          placeholder='Ask me anything or paste a product URL'
          value={messageText}
        />
        <svg
          fill='none'
          height='30'
          onClick={() => {
            if (messageText) {
              onGetConversation(messageText)
              setMessageText(undefined)
            }
          }}
          viewBox='0 0 30 30'
          width='30'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect fill='#814EC2' height='30' rx='15' width='30' />
          <rect height='29' rx='14.5' stroke='#5C657B' strokeOpacity='0.04' width='29' x='0.5' y='0.5' />
          <g clipPath='url(#clip0_688_2526)'>
            <path
              d='M9.57485 8.97882C8.63149 8.56319 7.69086 9.56944 8.16664 10.4855L9.8893 13.7995C10.0096 14.0292 10.2338 14.1851 10.4909 14.2179L15.2733 14.8167C15.3663 14.8276 15.4373 14.9069 15.4373 14.9999C15.4373 15.0929 15.3663 15.1722 15.2733 15.1831L10.4909 15.7819C10.2338 15.8148 10.0096 15.9706 9.8893 16.2003L8.16664 19.5144C7.69086 20.4304 8.63149 21.4339 9.57758 21.021L21.4776 15.8011C22.1748 15.4948 22.1748 14.505 21.4776 14.1987L9.57485 8.97882Z'
              fill='white'
            />
          </g>
          <defs>
            <clipPath id='clip0_688_2526'>
              <rect fill='white' height='14' transform='translate(8 8)' width='14' />
            </clipPath>
          </defs>
        </svg>
      </SCSideKickInputContainer>
    </>
  )
}

export { SideKickInput }
