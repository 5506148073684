// React
import { createRoot } from 'react-dom/client'
// 3rd Party - LogRocket
import LogRocket from 'logrocket'
// Local - App
import Layout from 'layout'

// Check to see if the app is running in development mode
const isDev = location.hostname === 'localhost' || location.hostname === '127.0.0.1'

if (!isDev) {
  // Initialize LogRocket
  LogRocket.init(import.meta.env.VITE_REACT_APP_LOGROCKET_PURCHASE_PRO_APP_ID ?? '')

  // Set session URL for use later
  LogRocket.getSessionURL((sessionURL: any) => {
    window.localStorage.setItem('logrocket_session_url', sessionURL)
  })
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(<Layout />)
}
