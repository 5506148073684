import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'context'
import { SCSideKickViewsChatAvatarUserInitials } from './styles'

const SideKickViewsChatAvatarUser: React.FC = () => {
  // Global - Context
  const { loggedInUser } = useContext(AppContext)
  // View State - Data
  const [userInitial, setUserInitials] = useState<string>('')

  useEffect(() => {
    if (loggedInUser.friendlyName) {
      const firstName = loggedInUser.friendlyName?.split(' ')[0]
      const lastName = loggedInUser.friendlyName?.split(' ')[1]

      if (firstName && lastName) {
        setUserInitials(`${firstName[0]}${lastName[0]}`.toUpperCase())
      }
      if (firstName) {
        setUserInitials(`${firstName[0]}`.toUpperCase())
      }
    }

    setUserInitials('MS')
  }, [loggedInUser])

  return (
    <>
      <SCSideKickViewsChatAvatarUserInitials>{userInitial}</SCSideKickViewsChatAvatarUserInitials>
    </>
  )
}

export { SideKickViewsChatAvatarUser }
