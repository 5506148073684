const SideKickViewsHomeIconsLightbulb: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: '#E27100',
        display: 'flex',
        width: '48px',
        height: '48px',
        padding: '10px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px'
      }}
    >
      <svg fill='none' height='28' viewBox='0 0 24 28' width='24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.0909 20.5C16.5818 18.8801 17.5994 17.4988 18.6068 16.1227C18.8727 15.7621 19.1386 15.4016 19.3943 15.0359C20.4068 13.5887 21 11.8367 21 9.94258C21 5.00156 16.9705 1 12 1C7.02955 1 3 5.00156 3 9.9375C3 11.8316 3.59318 13.5887 4.60568 15.0309C4.86136 15.3965 5.12727 15.757 5.39318 16.1176C6.40568 17.4938 7.4233 18.8801 7.90909 20.4949H16.0909V20.5ZM12 27C14.2602 27 16.0909 25.182 16.0909 22.9375V22.125H7.90909V22.9375C7.90909 25.182 9.73977 27 12 27ZM7.90909 9.9375C7.90909 10.3844 7.54091 10.75 7.09091 10.75C6.64091 10.75 6.27273 10.3844 6.27273 9.9375C6.27273 6.79414 8.83466 4.25 12 4.25C12.45 4.25 12.8182 4.61562 12.8182 5.0625C12.8182 5.50938 12.45 5.875 12 5.875C9.73977 5.875 7.90909 7.69297 7.90909 9.9375Z'
          fill='#FCF1E5'
        />
      </svg>
    </div>
  )
}

export { SideKickViewsHomeIconsLightbulb }
