const SideKickViewsWelcomeCheckmark: React.FC = () => {
  return (
    <svg fill='none' height='15' viewBox='0 0 17 15' width='17' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_395_4494)'>
        <path
          d='M16.6532 0.712102C17.1144 1.2755 17.1144 2.19047 16.6532 2.75387L7.20879 14.2923C6.74764 14.8557 5.99872 14.8557 5.53757 14.2923L0.815348 8.5231C0.354194 7.9597 0.354194 7.04473 0.815348 6.48133C1.2765 5.91793 2.02542 5.91793 2.48657 6.48133L6.37503 11.2274L14.9857 0.712102C15.4469 0.1487 16.1958 0.1487 16.6569 0.712102H16.6532Z'
          fill='#6327AD'
        />
      </g>
      <defs>
        <clipPath id='clip0_395_4494'>
          <rect fill='white' height='15' width='17' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { SideKickViewsWelcomeCheckmark }
