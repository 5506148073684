// MainStem - UI
import { Loader, SelectSingle } from '@mainstem/react-mainstem'
// Local - Types
import { ILoaderSelect } from './types'

const LoaderSelect = ({ label }: ILoaderSelect) => {
  return (
    <>
      <SelectSingle
        disabled={true}
        formGroupStyle={{ marginBottom: 0 }}
        label={label}
        onChange={() => {
          // DO Nothing
        }}
        options={[]}
        placeholder={
          <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>
                <Loader containerHeight={20} size='sm' />
              </div>
              <div>&nbsp;Loading...</div>
            </div>
          </>
        }
        value={undefined}
      />
    </>
  )
}

export { LoaderSelect }
