import styled from 'styled-components'

export const SCSideKickViewsHomeActionTileWrapper = styled.div`
  margin: 5px;
`

export const SCSideKickViewsHomeActionTile = styled.div`
  border: 1px solid rgb(222, 224, 229);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  :hover {
    background-color: rgb(245, 245, 245);
    cursor: pointer;

    h2 {
      color: #440099;
    }
  }
`

export const SCSideKickViewsHomeActionTileTitle = styled.h2`
  color: rgb(12, 9, 9);
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`

export const SCSideKickViewsHomeActionTileDescription = styled.p`
  color: rgb(74, 81, 98);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`
