// React
import { useContext, useEffect, useRef } from 'react'
// React - Markdown
import Markdown from 'react-markdown'
// MainStem - UI
import { toast } from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi } from 'api-new'
// Global - Context
import { AppContext } from 'context'
// Local - Components
import {
  SideKickViewsChatAvatarBot,
  SideKickViewsChatAvatarUser,
  SideKickViewsChatIconCopy,
  SideKickViewsChatIconThumbsDown,
  SideKickViewsChatIconThumbsUp
} from './components'
// Local - Styles
import {
  SCSideKickViewsChatMessageOptionsBot,
  SCSideKickViewsChat,
  SCPageWrapperSidebarLoader,
  SCSideKickViewsChatMessages,
  SCSideKickViewsChatMessageOptionsUser,
  SCSideKickViewsChatMessage,
  SCSideKickViewsChatMessageContent
} from './styles'
// Local - Types
import { ISideKickViewsChat } from './types'

const SideKickViewsChat: React.FC<ISideKickViewsChat> = ({ loading }: ISideKickViewsChat) => {
  // Global - Context
  const { messages } = useContext(AppContext)
  // Local - Refs
  const messagesViewRef = useRef<HTMLDivElement>(null)

  const handleMessageLike = (index: number) => {
    const previousMessage = messages[index - 1]
    const currentMessage = messages[index]

    const apiRequest = {
      currentMessage: currentMessage?.message?.toString() || '',
      previousMessage: previousMessage?.message?.toString() || ''
    }

    MainStemApi.api.sidekickConversationMessageLike(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Thank you for your feedback.  We have recorded your response.')
      }
    })
  }

  const handleMessageDislike = (index: number) => {
    const previousMessage = messages[index - 1]
    const currentMessage = messages[index]

    const apiRequest = {
      currentMessage: currentMessage?.message?.toString() || '',
      previousMessage: previousMessage?.message?.toString() || ''
    }

    MainStemApi.api.sidekickConversationMessageDislike(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        toast.success('Thank you for your feedback.  We have recorded your response.')
      }
    })
  }

  /**
   * Copy the content of the message to the users clipboard
   */
  const handleMessageCopy = (message) => {
    // Extract the HTML content or text from the React node
    const messageText =
      typeof message === 'string'
        ? message
        : (message && (message as any).props.dangerouslySetInnerHTML?.__html) ||
          (message && (message as any).props.children.props.children[1].props?.children)

    navigator.clipboard
      .writeText(messageText)
      .then(() => {
        toast.success('Message copied to clipboard')
      })
      .catch(() => {
        toast.error('Failed to copy message')
      })
  }

  // Anytime "messages" or "loading" changes, scroll to the bottom of the chat
  useEffect(() => {
    if (messagesViewRef.current) {
      messagesViewRef.current.scrollTop = messagesViewRef.current.scrollHeight
    }
  }, [loading, messages])

  return (
    <>
      <SCSideKickViewsChat>
        <SCSideKickViewsChatMessages ref={messagesViewRef}>
          {messages.map((message, index) => {
            return (
              <>
                <SCSideKickViewsChatMessage
                  key={index}
                  style={{
                    flexDirection: message.type === 'bot' ? 'row' : 'row-reverse'
                  }}
                >
                  <div>{message.type === 'bot' ? <SideKickViewsChatAvatarBot /> : <SideKickViewsChatAvatarUser />}</div>
                  <SCSideKickViewsChatMessageContent
                    style={{
                      backgroundColor: message.type === 'bot' ? '#FFFFFF' : '#6327AD',
                      color: message.type === 'bot' ? '#5C657B' : '#FFFFFF',
                      margin: message.type === 'bot' ? '2px 0px 0px 5px' : '2px 5px 0px 0px',
                      borderRadius: message.type === 'bot' ? '0px 6px 6px 6px' : '6px 0px 6px 6px'
                    }}
                  >
                    {typeof message.message === 'string' ? <Markdown>{message.message}</Markdown> : message.message}
                  </SCSideKickViewsChatMessageContent>
                </SCSideKickViewsChatMessage>
                {message.type === 'bot' && (
                  <>
                    <SCSideKickViewsChatMessageOptionsBot>
                      <div>
                        <SideKickViewsChatIconThumbsUp onClick={() => handleMessageLike(index)} />
                      </div>
                      <div>
                        <SideKickViewsChatIconThumbsDown onClick={() => handleMessageDislike(index)} />
                      </div>
                      <div>
                        <SideKickViewsChatIconCopy onClick={() => handleMessageCopy(message.message)} />
                      </div>
                    </SCSideKickViewsChatMessageOptionsBot>
                  </>
                )}
                {message.type === 'user' && (
                  <>
                    <SCSideKickViewsChatMessageOptionsUser>
                      <div>
                        <SideKickViewsChatIconCopy onClick={() => handleMessageCopy(message.message)} />
                      </div>
                    </SCSideKickViewsChatMessageOptionsUser>
                  </>
                )}
              </>
            )
          })}
          {loading && (
            <>
              <SCSideKickViewsChatMessage>
                <SideKickViewsChatAvatarBot />
                <SCPageWrapperSidebarLoader />
              </SCSideKickViewsChatMessage>
            </>
          )}
        </SCSideKickViewsChatMessages>
      </SCSideKickViewsChat>
    </>
  )
}

export { SideKickViewsChat }
