import styled from 'styled-components'

export const SC_SideKickTitle = styled.div`
  background-color: #0000000;
  border-radius: 8px 8px 0px 0px;
  color: #242424;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: sticky;
  text-align: left;
  padding: 24px;
  width: 100%;
`

export const SC_SideKickTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SC_SideKickTitleVersion = styled.div`
  color: #7d8495;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const SC_SideKickTitleWrapperLogo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  transition: all 0.2s ease-in-out;

  :hover {
    color: #440099;
    cursor: pointer;
  }
`
export const SC_SideKickTitleWrapperLogoImg = styled.div``

export const SC_SideKickTitleWrapperLogoTitle = styled.div``
