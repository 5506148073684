const SideKickViewsHomeIconsTruck: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: '#EBE1F7',
        display: 'flex',
        width: '48px',
        height: '48px',
        padding: '10px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px'
      }}
    >
      <svg fill='none' height='28' viewBox='0 0 24 28' width='24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.2 4C3.20625 4 2.4 4.79785 2.4 5.78125V7.5625H0.6C0.27 7.5625 0 7.82969 0 8.15625C0 8.48281 0.27 8.75 0.6 8.75H2.4H10.2C10.53 8.75 10.8 9.01719 10.8 9.34375C10.8 9.67031 10.53 9.9375 10.2 9.9375H2.4H1.8C1.47 9.9375 1.2 10.2047 1.2 10.5312C1.2 10.8578 1.47 11.125 1.8 11.125H2.4H9C9.33 11.125 9.6 11.3922 9.6 11.7188C9.6 12.0453 9.33 12.3125 9 12.3125H2.4H0.6C0.27 12.3125 0 12.5797 0 12.9062C0 13.2328 0.27 13.5 0.6 13.5H2.4H7.8C8.13 13.5 8.4 13.7672 8.4 14.0938C8.4 14.4203 8.13 14.6875 7.8 14.6875H2.4V19.4375C2.4 21.4043 4.0125 23 6 23C7.9875 23 9.6 21.4043 9.6 19.4375H14.4C14.4 21.4043 16.0125 23 18 23C19.9875 23 21.6 21.4043 21.6 19.4375H22.8C23.4638 19.4375 24 18.9068 24 18.25C24 17.5932 23.4638 17.0625 22.8 17.0625V14.6875V13.5V12.8061C22.8 12.1752 22.5487 11.5703 22.0988 11.125L19.2 8.25645C18.75 7.81113 18.1388 7.5625 17.5013 7.5625H15.6V5.78125C15.6 4.79785 14.7938 4 13.8 4H4.2ZM20.4 12.8061V13.5H15.6V9.9375H17.5013L20.4 12.8061ZM6 17.6562C6.47739 17.6562 6.93523 17.8439 7.27279 18.178C7.61036 18.512 7.8 18.9651 7.8 19.4375C7.8 19.9099 7.61036 20.363 7.27279 20.697C6.93523 21.0311 6.47739 21.2188 6 21.2188C5.52261 21.2188 5.06477 21.0311 4.72721 20.697C4.38964 20.363 4.2 19.9099 4.2 19.4375C4.2 18.9651 4.38964 18.512 4.72721 18.178C5.06477 17.8439 5.52261 17.6562 6 17.6562ZM16.2 19.4375C16.2 18.9651 16.3896 18.512 16.7272 18.178C17.0648 17.8439 17.5226 17.6562 18 17.6562C18.4774 17.6562 18.9352 17.8439 19.2728 18.178C19.6104 18.512 19.8 18.9651 19.8 19.4375C19.8 19.9099 19.6104 20.363 19.2728 20.697C18.9352 21.0311 18.4774 21.2188 18 21.2188C17.5226 21.2188 17.0648 21.0311 16.7272 20.697C16.3896 20.363 16.2 19.9099 16.2 19.4375Z'
          fill='#6327AD'
        />
      </svg>
    </div>
  )
}

export { SideKickViewsHomeIconsTruck }
