// Styled Components
import styled from 'styled-components'

// Can't Find
export const SC_CantFind = styled.div`
  border-top: 1px dotted #ccc;
  color: #00affa;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  padding: 5px 0px;
  text-align: center;
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`
// Other Products
export const SC_Products_Others_Container = styled.div`
  border: 1px solid #e0e0e0;
  border-bottom: 0px none;
`

export const SC_Products_Others_Title = styled.div`
  background-color: #ecf2f9;
  border-bottom: 1px solid #ccc;
  font-size: 15px;
  font-weight: 600;
  padding: 15px;
`
