import styled from 'styled-components'

export const SCSideKickViewsChat = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`

export const SCSideKickViewsChatMessages = styled.div`
  flex-grow: 1;
  max-height: calc(100vh - 198px);
  overflow-y: scroll;
  padding: 15px;

  ::-webkit-scrollbar {
    width: 4px;
    background: #ecebeb;
    box-shadow: none;
    z-index: 9999;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b0adad;
    height: 8px;
    border-radius: 8px;
    box-shadow: none;
  }
`

export const SCSideKickViewsChatMessage = styled.div`
  display: flex;
  border-radius: 8px;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 10px;
`

export const SCSideKickViewsChatMessageContent = styled.div`
  border: 1px solid #e8e8e8;
  max-width: 90%;
  overflow-x: auto;
  padding: 10px;

  p {
    margin: 0px;
  }
`

export const SCSideKickViewsChatMessageOptionsBot = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  bottom: 25px;
  right: -50px;
  width: fit-content;
  color: #6327ad;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  :hover {
    cursor: pointer;
  }

  svg:hover path {
    fill: #6327ad;
  }
`

export const SCSideKickViewsChatMessageOptionsUser = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  bottom: 25px;
  left: 495px;
  width: fit-content;
  color: #6327ad;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  min-width: 76px;
  justify-content: flex-end;

  :hover {
    cursor: pointer;
  }

  svg:hover path {
    fill: #6327ad;
  }
`

export const SCPageWrapperSidebarLoader = styled.div`
  height: 10px;
  margin: 10px 10px 10px 10px;
  aspect-ratio: 2.5;
  --_g: no-repeat radial-gradient(farthest-side, #5c657b73 90%, #0000);
  background: var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
  animation: l43 1s infinite linear;
  @keyframes l43 {
    0% {
      background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
    16.67% {
      background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
    }
    33.33% {
      background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 0, calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
    }
    50% {
      background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 100%, calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 50%;
    }
    66.67% {
      background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 0;
    }
    83.33% {
      background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 100%;
    }
    100% {
      background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%, calc(2 * 100% / 3) 50%,
        calc(3 * 100% / 3) 50%;
    }
  }
`
