const SideKickFooter: React.FC = () => {
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          gap: 10,
          marginTop: 5
        }}
      >
        <div
          style={{
            color: '#7D8495',
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px'
          }}
        >
          SideKick uses AI and may make mistakes.
          <a href='https://www.mainstem.io/platform/sidekick' rel='noopener noreferrer' target='_blank'>
            <button
              style={{ textDecoration: 'underline', border: 'none', color: '#7D8495', backgroundColor: '#FAFAFB' }}
            >
              Learn more about SideKick
            </button>
          </a>
          <svg fill='none' height='12' viewBox='0 0 12 12' width='12' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11.8898 6.26483C12.0352 6.11952 12.0352 5.88046 11.8898 5.73514L8.51484 2.36014C8.36953 2.21483 8.13047 2.21483 7.98516 2.36014C7.83984 2.50546 7.83984 2.74452 7.98516 2.88983L10.7203 5.62499H0.375C0.16875 5.62499 0 5.79374 0 5.99999C0 6.20624 0.16875 6.37499 0.375 6.37499H10.7203L7.98516 9.11014C7.83984 9.25546 7.83984 9.49452 7.98516 9.63983C8.13047 9.78514 8.36953 9.78514 8.51484 9.63983L11.8898 6.26483Z'
              fill='#7D8495'
            />
          </svg>
        </div>
      </div>
    </>
  )
}

export { SideKickFooter }
