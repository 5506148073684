// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import { Button, theme } from '@mainstem/react-mainstem'
// Global - Components
import { ButtonGoBack, ModalQuickRFQ } from 'components'
// Global - Config
import { copilotURL } from 'config'
// Local - Components
import { SearchLive } from './components'

const NavTopButtons: React.FC = () => {
  // Routing
  const history = useHistory()
  // Modal
  const [showModalQuickRFQ, setShowModalQuickRFQ] = useState(false)

  return (
    <>
      <ButtonGoBack />
      <Button
        color='dark'
        icon={theme.icons.mainstem.rfq}
        onClick={() => {
          setShowModalQuickRFQ(true)
        }}
      >
        Quick RFQ™
      </Button>
      <div style={{ minWidth: '550px', width: '100%' }}>
        <SearchLive />
      </div>
      {showModalQuickRFQ && (
        <ModalQuickRFQ
          onClose={() => {
            setShowModalQuickRFQ(false)
          }}
        />
      )}
    </>
  )
}

export default NavTopButtons
