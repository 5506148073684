import {
  SCSideKickViewsHomeActionTile,
  SCSideKickViewsHomeActionTileDescription,
  SCSideKickViewsHomeActionTileTitle,
  SCSideKickViewsHomeActionTileWrapper
} from './styles'
import { ISideKickViewsHome } from './types'

const SideKickViewsHomeActionTile: React.FC<ISideKickViewsHome> = ({
  description,
  icon,
  onClick,
  title
}: ISideKickViewsHome) => {
  return (
    <SCSideKickViewsHomeActionTileWrapper>
      <SCSideKickViewsHomeActionTile onClick={onClick}>
        {icon}
        <SCSideKickViewsHomeActionTileTitle>{title}</SCSideKickViewsHomeActionTileTitle>
        <SCSideKickViewsHomeActionTileDescription>{description}</SCSideKickViewsHomeActionTileDescription>
      </SCSideKickViewsHomeActionTile>
    </SCSideKickViewsHomeActionTileWrapper>
  )
}

export { SideKickViewsHomeActionTile }
