// React
import { useState } from 'react'
// MainStem - UI
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextArea,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi } from 'api-new'
// Icons
import { faFileCertificate } from '@fortawesome/pro-light-svg-icons'
// Local - Types
import { IModalQuickRFQ } from './types'

const ModalQuickRFQ: React.FC<IModalQuickRFQ> = ({ onClose }: IModalQuickRFQ) => {
  // View State - Input
  const [message, setMessage] = useState<string>('')
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const handleSendMessage = () => {
    setLoading(true)

    const apiRequest = {
      comment: message,
      reason: 'RFQ Request'
    }

    MainStemApi.api.contactSendMessage(apiRequest).then((response) => {
      toast.success('Message sent successfully')
      onClose()
    })
  }

  return (
    <>
      <Modal
        onClose={() => {
          if (!loading) {
            onClose()
          }
        }}
      >
        <ModalHeader>MainStem Quick RFQ™</ModalHeader>
        <ModalBody>
          <p>Easily start a new RFQ and have the MainStem team review your request and provide a quote.</p>
          <p>
            If you prefer, you can send an email directly to our team at{' '}
            <a href='mailto:rfq@mainstem.io' target='_blank' rel='noreferrer'>
              rfq@mainstem.io
            </a>
          </p>
          <TextArea
            label='Describe your request'
            onChange={(newText) => {
              setMessage(newText)
            }}
            value={message}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup style={{ width: '100%' }}>
            <Button
              block
              color='danger'
              disabled={loading}
              icon={theme.icons.cancel}
              onClick={() => {
                if (!loading) {
                  onClose()
                }
              }}
            >
              Cancel
            </Button>
            <Button
              block
              color='primary'
              icon={faFileCertificate}
              loading={loading}
              onClick={() => {
                handleSendMessage()
              }}
            >
              Send Message
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  )
}
export { ModalQuickRFQ }
