// Routing
import { Link } from 'react-router-dom'
// Styled Components
import styled from 'styled-components'

export const SC_Container = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 15px;
  position: relative;

  &:hover {
    background-color: #eaf7ff;
  }
`

export const SC_ProductDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 15px;
`

export const SC_ProductDetails_ImageWrapper = styled.div`
  img {
    border: 1px solid #e0e0e0;
    width: 100px;
  }
`

export const SC_ProductDetails_Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const SC_ProductDetails_Content_Title = styled(Link)`
  color: #333;
  font-weight: 600;
`

export const SC_ProductDetails_Content_Price = styled.div`
  font-size: 20px;
  font-weight: 600;
`
